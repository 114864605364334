import * as React from "react";
import * as styles from "./privacy-policy.module.css";

import { FunctionComponent } from "react";
import Layout from "../components/Base/Layout";

const PrivacyPolicy: FunctionComponent = () => {
  return (
    <Layout>
    <div className={styles.privacyPolicyDiv}>
      <div className={styles.docTemplateDiv}>
        <div className={styles.textColumnDiv}>
          <div className={styles.subtitleLargeDiv}>
            <b className={styles.displayB}>Privacy Policy</b>
          </div>
          <div className={styles.textbodyDiv}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                At Global Care Supply, accessible from globalcaresupply.net, we
                prioritize the privacy of our visitors. This Privacy Policy
                document contains types of information that is collected,
                disclosed, and recorded by Global Care Supply and how we use it.
                We have formulated guidelines and standards for handling
                visitors’ information while also preserving their privacy and
                security.
              </div>
            </div>
            <div className={styles.pDiv1}>
              <div className={styles.captionDiv}>
                By using our service, we assume you agree to the collection and
                usage of your personal data in accordance with this document. In
                order to process product requests and purchases, we will
                accumulate your detailed personal information and obtain consent
                to disclose the available data to our service providers/partners
                and healthcare professionals for a more suitable transaction.
              </div>
            </div>
          </div>
          <div className={styles.subtitleLargeDiv1}>
            <div className={styles.displayDiv}>
              Collecting and Using Your Personal Information
            </div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv1}>Types of Data Collected</div>
          </div>
          <div className={styles.textbodyDiv}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                <p className={styles.personalData}>
                  <b>Personal Data</b>
                </p>
                <p className={styles.blankLineP}>
                  <span>&nbsp;</span>
                </p>
                <p className={styles.asYouUseOurServiceYouWi}>
                  <span>
                    As you use our service, you will be asked to provide us with
                    your personal data to use for contacting and identifying
                    purposes. Your personal data or information may include, but
                    is not limited to:
                  </span>
                </p>
              </div>
            </div>
            <div className={styles.pDiv3}>
              <div className={styles.captionDiv}>
                <ul className={styles.firstAndLastNameEmailAddr}>
                  <li className={styles.firstAndLast}>First and last name</li>
                  <li className={styles.firstAndLast}>Email address</li>
                  <li className={styles.firstAndLast}>Phone number</li>
                  <li>
                    Address (Street address, state, city, province, ZIP/Postal
                    code)
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.textbodyDiv}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                <p className={styles.personalData}>
                  <b>Usage Data</b>
                </p>
                <p className={styles.blankLineP}>
                  <span>&nbsp;</span>
                </p>
                <p className={styles.asYouUseOurServiceYouWi}>
                  <span>
                    Our system will automatically collect your usage data when
                    using our service. The information under your usage date
                    includes the following:
                  </span>
                </p>
              </div>
            </div>
            <div className={styles.pDiv3}>
              <div className={styles.captionDiv}>
                <p className={styles.personalData}>
                  Your device’s internet protocol address (IP address)
                </p>
                <ul className={styles.firstAndLastNameEmailAddr}>
                  <li className={styles.firstAndLast}>Type of browser</li>
                  <li className={styles.firstAndLast}>Version of browser</li>
                  <li className={styles.firstAndLast}>Page you visit</li>
                  <li className={styles.firstAndLast}>
                    Time and date of visit
                  </li>
                  <li className={styles.firstAndLast}>
                    Unique device identifier
                  </li>
                </ul>
                <p className={styles.personalData}>&nbsp;</p>
                <p className={styles.personalData}>
                  The same information collection will also happen if you use a
                  mobile device. We may collect details, including the type of
                  mobile device you use, unique device ID, operating system, and
                  IP address
                </p>
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv1}>Use of Your Personal Data</div>
          </div>
          <div className={styles.textbodyDiv}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                Global Care Supply reserves the right to use your data for
                the following purposes:
              </div>
            </div>
            <div className={styles.pDiv7}>
              <div className={styles.captionDiv}>
                <ul className={styles.firstAndLastNameEmailAddr}>
                  <li className={styles.firstAndLast}>
                    <b className={styles.managingYourAccount}>
                      Managing your account
                    </b>
                    <span className={styles.span}>{` `}</span>
                    <span>
                      – To manage your registration as a user or customer of our
                      service. Your data will provide you with access to our
                      service’s functionalities.
                    </span>
                  </li>
                  <li className={styles.firstAndLast}>
                    <b className={styles.managingYourAccount}>
                      Contact and updating
                    </b>
                    <span className={styles.span}>{` `}</span>
                    <span className={styles.managingYourAccount}>
                      – We will use your information to contact you through
                      email, telephone calls, SMS, or other forms of electronic
                      communication. We also provide updates related to our
                      service’s functionalities and product implementations.
                    </span>
                  </li>
                  <li className={styles.firstAndLast}>
                    <b className={styles.managingYourAccount}>
                      Performing your contract
                    </b>
                    <span className={styles.span}>{` `}</span>
                    <span className={styles.managingYourAccount}>
                      – This includes the development, compliance, and
                      undertaking of your purchases with our products.
                    </span>
                  </li>
                  <li className={styles.firstAndLast}>
                    <b className={styles.managingYourAccount}>
                      Notifying with offers
                    </b>
                    <span className={styles.managingYourAccount}>
                      {" "}
                      – As part of our service, we occasionally inform you of
                      special offers, goods, services, and events in which you
                      can participate. However, you can opt not to receive such
                      information or details.
                    </span>
                  </li>
                  <li className={styles.firstAndLast}>
                    <b className={styles.managingYourAccount}>Other purposes</b>
                    <span className={styles.span}>{` `}</span>
                    <span className={styles.managingYourAccount}>
                      – We may use your personal information for other purposes
                      related to our company’s promotional campaigns, service
                      improvement, product and services, and marketing for a
                      better purchase experience.
                    </span>
                  </li>
                  <li className={styles.firstAndLast}>
                    <b className={styles.managingYourAccount}>
                      With Your consent
                    </b>
                    <span className={styles.span}>{`: `}</span>
                    <span className={styles.managingYourAccount}>
                      We may disclose your personal information for any other
                      purpose.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                <b>{`Note: `}</b>
                <span>
                  We can only disclose your personal information with your
                  consent.
                </span>
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv1}>
              Disclosure of Your Personal Data
            </div>
          </div>
          <div className={styles.textbodyDiv4}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                Global Care Supply shall not disclose your personal information
                for purposes other than those for which it was collected. This
                involves transactions and important adherence to the following:
              </div>
            </div>
          </div>
          <div className={styles.pDiv10}>
            <div className={styles.captionDiv}>
              <ul className={styles.firstAndLastNameEmailAddr}>
                <li className={styles.firstAndLast}>
                  <b className={styles.span}>{`Law enforcement – `}</b>
                  <span>
                    Global Care Supply shall not use or disclose your personal
                    information except for legal obligations or as required by
                    law. Our company may be required to disclose your
                    information if it’s subject to public authorities or
                    government agencies.
                  </span>
                </li>
              </ul>
              <p className={styles.personalData}>
                <span>&nbsp;</span>
              </p>
              <ul className={styles.firstAndLastNameEmailAddr}>
                <li>
                  <b className={styles.span}>{`Other legal requirements - `}</b>
                  <span>
                    Global Care Supply may disclose your personal data if it is
                    necessary to comply with a legal obligation, defend the
                    company’s rights, investigate possible wrongdoings within
                    the service, protect the personal safety of the users, and
                    safeguard against legal liability.
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv1}>
              Security of Your Personal Data
            </div>
          </div>
          <div className={styles.textbodyDiv4}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                <p className={styles.personalData}>
                  Global Care Supply recognizes the importance of your personal
                  data. Our website shall engage in security safeguards to
                  protect your personal information against the modification,
                  copying, disclosure, loss or theft, and unauthorized access
                  for illegal use.
                </p>
                <p className={styles.personalData}>&nbsp;</p>
                <p className={styles.asYouUseOurServiceYouWi}>
                  Rest assured that your sensitive information, such as your
                  credit card number, will be protected on our order forms. We
                  encrypt the transmission of that data using our secure socket
                  layer technology (SSL). We strive to secure your personal data
                  and use commercially acceptable means to execute such action.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv1}>Delete Your Personal Data</div>
          </div>
          <div className={styles.textbodyDiv4}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                Like other websites, Global Care Supply also uses cookies. These
                cookies store information, including visitors’ preferences and
                the pages on the website that the visitor accessed. Through the
                use of cookies, we can optimize our visitor's or users'
                experience by customizing any feature of our website. However,
                you can also opt to disable the use of cookies by adjusting the
                settings on our web browser.
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv1}>
              Changes to this Privacy Policy
            </div>
          </div>
          <div className={styles.textbodyDiv4}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                We may update this Privacy Policy document and make necessary
                changes for our website’s development. We will inform you of any
                changes through your contact information. We will also post a
                newly detailed Privacy Policy on this page, so we advise you to
                review and browse this page from time to time.
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv1}>Contact us</div>
          </div>
          <div className={styles.textbodyDiv4}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                We believe that customer satisfaction is of utmost importance.
                If you have more concerns or questions with regard to this
                Privacy Policy statement, do not hesitate to contact us through
                our <a className={styles.link} href="/contact-us">Contact Us</a> page or email us at <a className={styles.link} href="mailto:info@globalcaresupply.net">info@globalcaresupply.net.</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Layout>
  );
};

export default PrivacyPolicy;
