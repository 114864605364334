// extracted by mini-css-extract-plugin
export var asYouUseOurServiceYouWi = "privacy-policy-module--asYouUseOurServiceYouWi--cc850";
export var blankLineP = "privacy-policy-module--blankLineP--da317";
export var captionDiv = "privacy-policy-module--captionDiv--2b015";
export var displayB = "privacy-policy-module--displayB--8a2cb";
export var displayDiv = "privacy-policy-module--displayDiv--9b53f";
export var displayDiv1 = "privacy-policy-module--displayDiv1--ba667";
export var docTemplateDiv = "privacy-policy-module--docTemplateDiv--e0296";
export var firstAndLast = "privacy-policy-module--firstAndLast--78946";
export var firstAndLastNameEmailAddr = "privacy-policy-module--firstAndLastNameEmailAddr--61f78";
export var link = "privacy-policy-module--link--e8c84";
export var managingYourAccount = "privacy-policy-module--managingYourAccount--968a7";
export var pDiv = "privacy-policy-module--pDiv--1d011";
export var pDiv1 = "privacy-policy-module--pDiv1--8b408";
export var pDiv10 = "privacy-policy-module--pDiv10--72e24";
export var pDiv3 = "privacy-policy-module--pDiv3--b47e7";
export var pDiv7 = "privacy-policy-module--pDiv7--cea0b";
export var personalData = "privacy-policy-module--personalData--dad56";
export var privacyPolicyDiv = "privacy-policy-module--privacyPolicyDiv--9483e";
export var span = "privacy-policy-module--span--57906";
export var subtitleDiv = "privacy-policy-module--subtitleDiv--54449";
export var subtitleLargeDiv = "privacy-policy-module--subtitleLargeDiv--2639e";
export var subtitleLargeDiv1 = "privacy-policy-module--subtitleLargeDiv1--521f9";
export var textColumnDiv = "privacy-policy-module--textColumnDiv--88480";
export var textbodyDiv = "privacy-policy-module--textbodyDiv--3a1f0";
export var textbodyDiv4 = "privacy-policy-module--textbodyDiv4--b2dc9";